import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie} from "cookies-next";
import {faArrowTurnDownRight, faMugHot, faPen, faPlus, faPlusCircle, faTrash} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DialogGroupsModalEditAdd from './DialogGroupsModalEditAdd';
import messages from '../../../../src/messages';
import * as Sentry from '@sentry/nextjs';
import DialogDeleteGroups from './DialogDeleteGroups';
import DialogAlert from './DialogAlert';
import { FormattedMessage } from 'react-intl';


export default function DialogMove ({productMoveUUID,currentPageTop,
                                      toggleMove,reloadProducts}) {

    const [loadingMove, setLoadingMove] = useState(false);
    const [moveShow, setMoveShow] = useState(false);
    const [moveTransitionShow, setMoveTransitionShow] = useState(false);

    const changeMoveShow = (variable) => {

        if(variable === true){

            setMoveShow(variable);
            setTimeout(function() {
                setMoveTransitionShow(true);
            },1);




        } else{


            setMoveTransitionShow(false);
            setTimeout(function() {
                setMoveShow(variable);
            },500);



        }

    };

    const [allGroups, setAllGroups] = useState(null);


    const [finalMoveUUID, selectFinalMoveUUID] = useState(null);


    const [checkedUUIDZ, setCheckedUUIDZ] = useState(null);

    const selectMoveDestination = (uuid) => {
        selectFinalMoveUUID(uuid);
   var checkedUUIDFirst = [uuid];
        var parentUUID = null;

        var parentClientUUID = null;

        allGroups.map((group, i) => {
         if(group.uuid === uuid){
            if(group.parent){
                parentUUID = group.parent;
                checkedUUIDFirst.push(group.parent);
            }
         }
        });

        allGroups.map((group, i) => {
            if(group.uuid === parentUUID){
                if(group.parent){
                    parentClientUUID = group.parent;
                    checkedUUIDFirst.push(parentClientUUID);
                }
            }
        });

        setCheckedUUIDZ(checkedUUIDFirst);
    };
    const moveProduct = () => {

        setLoadingMove(true);
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products/"+productMoveUUID, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify(    {
                "group":finalMoveUUID
            }),
        })
            .then(response => response.json())
            .then(function(data){
                if(data.status === 200){
                     changeMoveShow(false);

                    reloadProducts(currentPageTop);

                }

            });

    };

    const [showMoveDialog, setShowMoveDialog] = useState(false);
    const toggleMoveInside = useCallback((): void => {
        loadProductsFilters();
        if(showMoveDialog){
            changeMoveShow(false);
        }else{
            setCheckedUUIDZ(null);
            changeMoveShow(true);
        }


    }, []);
    useEffect(() => {

        toggleMove && toggleMove(toggleMoveInside);
    }, [toggleMove, toggleMoveInside]);



    const closeModalOutsideClick = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            changeMoveShow(false);
        }
    }

    const [activeThreeLocation, setActiveThreeLocation] = useState([]);



    const [allGroupsClients, setAllGroupsClients] = useState([]);
    const [allGroupsBrands, setAllGroupsBrands] = useState([]);
    const [allGroupsEditions, setAllGroupsEditions] = useState([]);



    useEffect(() => {

        loadProductsFilters();
    }, []);
    const loadProductsFilters = () => {

        var newClientsGroupsArr = [];
        var newBrandsGroupsArr = [];
        var newEditionsGroupsArr = [];


        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"groups", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){

                if(data.message === undefined){
                    var allGroupsData = data;

                    var newClientsGroups = data.map((dataItem, i) => {
                        if (dataItem.type == "client") {
                            newClientsGroupsArr.push(dataItem);

                        }
                    });
                    var newBrandsGroups = data.map((dataItem, i) => {
                        if (dataItem.type == "brand") {
                            newBrandsGroupsArr.push(dataItem);

                        }
                    });
                    var newEditionsGroups = data.map((dataItem, i) => {
                        if (dataItem.type == "edition") {
                            newEditionsGroupsArr.push(dataItem);
                        }
                    });


                    setAllGroupsClients(newClientsGroupsArr);
                    setAllGroupsBrands(newBrandsGroupsArr);
                    setAllGroupsEditions(newEditionsGroupsArr);

                    setAllGroups(data);

                    loadProductsFiltersAPI();
                }

            });
    };


    const [editAllName, changeEditAllName] = useState("");
    const [editAllType, changeEditAllType] = useState("");
    const [editAllIcon, changeEditAllIcon] = useState("");
    const [editAllUUID, changeEditAllUUID] = useState("");

    const [clientPlaceholderText, setClientPlaceholderText] = useState(messages.products_filter_clients.defaultMessage);
    const [brandPlaceholderText, setBrandPlaceholderText] = useState(messages.products_filter_brands.defaultMessage);
    const [editionPlaceholderText, setEditionPlaceholderText] = useState(messages.products_filter_editions.defaultMessage);

    const startEditAnything = (type, uuid, editName, logo) => {

        changeEditAllName(editName);
        changeEditAllIcon(logo);
        changeEditAllUUID(uuid);
        changeEditAllType(type);

        if(type === "client"){
            changeEditAllTypeHeaderText(clientPlaceholderText);
        }
        if(type === "brand"){
            changeEditAllTypeHeaderText(brandPlaceholderText);
        }
        if(type === "edition"){
            changeEditAllTypeHeaderText(editionPlaceholderText);
        }


        setModalShow(true, "editAll",null);
    }

    const [parentClient, setParentClient] = useState(undefined);


    const handleParentClient = function (e: React.FormEvent<HTMLInputElement>) {
        setParentClient(e.currentTarget.value);
    }


    const [parentBrand, setParentBrand] = useState(undefined);


    const handleParentBrand = function (e: React.FormEvent<HTMLInputElement>) {
        setParentBrand(e.currentTarget.value);
    }


    const setModalShow = (variable,  type, parentUUID) => {

        if(variable === true){
            // setLoadProductsShowVariable(variable);

            if(type === "client"){
                changeClientModal(true);
                setTimeout(function() {
                    setModalTransitionShow(true);
                }, 1);
            }
            if(type === "brand"){
                setParentClient(parentUUID);
                changeBrandModal(true);
                setTimeout(function() {
                    setModalTransitionShow(true);
                }, 1);
            }
            if(type === "edition"){
                setParentBrand(parentUUID);
                changeEditionsModal(true);
                setTimeout(function() {
                    setModalTransitionShow(true);
                }, 1);
            }
            if(type === "editAll"){
                changeEditAllModalActive(true);


                setTimeout(function() {
                    setModalTransitionShow(true);
                }, 1);
            }
        } else{

            setModalTransitionShow(false);
            setTimeout(function() {

                changeClientModal(false);
                changeBrandModal(false);
                changeEditionsModal(false);
                changeEditAllModalActive(false);

                //setLoadProductsShowVariable(variable);
            },300);

        }

    };

    const loadProductsFiltersAPI = () => {


        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"filter/products", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){
                if(data.message !== undefined){
                    Sentry.captureException("edit filter/products"+": "+ data.message + " : " + getCookie("userMail"));
                }
                setFilterClients(data.values.clients);
                setFilterBrands(data.values.brands);
                setFilterEditions(data.values.editions);
                setFilterProductTypes(data.values.product_type);
                setFilterSizes(data.values.volume);
            });



    };

    useEffect(() => {
        loadProductsFiltersAPI();

    }, []);



    const [filterClients, setFilterClients] = useState([]);
    const [filterBrands, setFilterBrands] = useState([]);
    const [filterEditions, setFilterEditions] = useState([]);
    const [filterProductTypes, setFilterProductTypes] = useState([]);
    const [filterSizes, setFilterSizes] = useState([]);

    const [modalTransitionShow, setModalTransitionShow] = useState(false);

    const [clientModalActive, changeClientModal] = useState(false);
    const [brandModalActive, changeBrandModal] = useState(false);
    const [editionsModalActive, changeEditionsModal] = useState(false);
    const [editAllModalActive, changeEditAllModalActive] = useState(false);
    const [editAllTypeHeaderText, changeEditAllTypeHeaderText] = useState(false);
    const [editAllDeletecheck, changeEditAllDeletecheck] = useState(false);

    const closeModalOutsideClickEdit = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            setModalShow(false, "editAll", null);
        }
    }

    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {
        // changeLoadingShowTop(false);
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    let handleTriggerWarning = () => {

    };


    let handleClickDelete = () => {

    };

    const showDelete  = (uuid) => {
        handleClickDelete();
        changeEditAllUUID(uuid);
        changeEditAllDeletecheck(true);
        //  setModalTransitionShow(true);
    }

    return (
        <>



            <DialogGroupsModalEditAdd
                modalTransitionShow={modalTransitionShow}
                editAllModalActive={editAllModalActive}
                editionsModalActive={editionsModalActive}
                clientModalActive={clientModalActive}
                brandModalActive={brandModalActive}
                editAllName={editAllName}
                editAllTypeHeaderText={editAllTypeHeaderText}
                editAllType={editAllType}
                editAllIcon={editAllIcon}
                editAllUUID={editAllUUID}
                setModalShow={setModalShow}
                changeEditAllName={changeEditAllName}
                filterClients={filterClients}
                loadProductsFilters={loadProductsFilters}
                parentClient={parentClient}
                handleParentClient={handleParentClient}
                parentBrand={parentBrand}
                handleParentBrand={handleParentBrand}
                filterBrands={filterBrands}
                closeModalOutsideClickEdit={closeModalOutsideClickEdit}
            />


            <DialogDeleteGroups
                editAllUUID={editAllUUID}
                toggleDelete={ (toggleDelete: () => void) => {
                    handleClickDelete = toggleDelete;
                } }
                loadProductsFiltersAPI={loadProductsFilters}
                changeEditAllUUID={changeEditAllUUID}
                triggerWarning={triggerWarning}

            />



            <DialogAlert
                toggleAlert={ (toggleAlert: () => void) => {
                    handleTriggerWarning = toggleAlert;
                } }
                alertType={"error"}
                message={warningMessage}
            />


            <ModalsWrapper className={`moveCheck  ${moveTransitionShow ? 'showTransition' : 'hideTransition'} ${!moveShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center modal_dialog_small"}
                    id={'moveProductDialog'} >
                    <div className={'modal-header'}>
                        <h4><FormattedMessage {...messages.products_modal_move_title}></FormattedMessage></h4>

                    </div>




                    <div className={'modal-content shareProduct'} style={{display:"block"}}>
                        <span ><FormattedMessage {...messages.products_modal_move_description}></FormattedMessage></span>
<br/><br/>
                        <GlobalButtonFinal
                            onClick={() => setModalShow(true, "client",null)}
                            id={"addNewClientBtn"}
                            style={{    width: "fit-content",  fontSize: "15px",  marginBottom: "30px"}}
                            className={"borderLightGray btn-circle"}>
                            <FontAwesomeIcon icon={faPlus} style={{ marginRight:"5px"}}

                            /><FormattedMessage {...messages.products_modal_move_button_add_new_client}></FormattedMessage>
                        </GlobalButtonFinal>

                        <div className={"moveThreeGroupsList"}>

                            <ul className={"moveTreeUL"}>

                                {allGroupsClients !== undefined && allGroupsClients.length > 0 ? (
                                    <>
                                        {allGroupsClients.map((value, index) => {
                                            return (
                                                <li
                                                    key={'filter-clients-'+index}
                                                    className={activeThreeLocation.includes(value.uuid) ? 'activeFilter' : ''}
                                                >
                                                    <div className={"papa"}>
                                                        <div className={"logoInput"}>
                                                            <input className="jss1595"
                                                                   type="checkbox"
                                                                   data-indeterminate="false"
                                                                   value=""
                                                                   checked={checkedUUIDZ && checkedUUIDZ.includes(value.uuid) ? true : false}
                                                                   onClick={() => selectMoveDestination(value.uuid)}
                                                                   onChange={() => selectMoveDestination(value.uuid)}
                                                            />
                                                        </div>
                                                        <div className={"content"}>
                                                            <div className={"logoInside"}
                                                            >
                                                                {value.logo ?
                                                                    <img src={value.logo }  />
                                                                    : <img style={{   position:"relative",  top: "-4px "}}
                                                                           src={'/images/logo.svg'} />
                                                                }

                                                            </div>
                                                            <p>{value.name}</p>

                                                        </div>

                                                        <div className={"content-buttons"}>

                                                            <button className={`optionsFilterItem`}
                                                                    onClick={() => setModalShow(true, "brand", value.uuid)}
                                                            >  <FontAwesomeIcon icon={faPlusCircle}/></button>

                                                            <button className={`optionsFilterItem`}
                                                                onClick={() => startEditAnything("client", value.uuid, value.name, value.logo)}
                                                            >  <FontAwesomeIcon icon={faPen}/></button>
                                                            <button className={`optionsFilterItem`}
                                                                onClick={() => showDelete(value.uuid)}
                                                            >  <FontAwesomeIcon icon={faTrash}/></button>

                                                        </div>
                                                    </div>
                                                    <div className={"nana"}>
                                                        <ul className={"moveTreeULSecondLevel"}>
                                                            {allGroupsBrands !== undefined && allGroupsBrands.length > 0 ? (
                                                                <>
                                                                    {allGroupsBrands.map((valueBrand, indexBrand) => {
                                                                        if(valueBrand.parent === value.uuid){
                                                                            return (
                                                                                <li
                                                                                    key={'filter-brands-'+indexBrand}
                                                                                    className={activeThreeLocation.includes(valueBrand.uuid) ? 'activeFilter' : ''}

                                                                                >
                                                                                    <div className={"papa"}>
                                                                                        <div className={"logoInput"}>
                                                                                            <input className="jss1595"
                                                                                                   type="checkbox"
                                                                                                   data-indeterminate="false"
                                                                                                   value=""
                                                                                                   checked={checkedUUIDZ && checkedUUIDZ.includes(valueBrand.uuid) ? true : false}
                                                                                                   onClick={() => selectMoveDestination(valueBrand.uuid)}
                                                                                                   onChange={() => selectMoveDestination(valueBrand.uuid)}
                                                                                                // onClick={type !== "assignProduct" ? e => chooseClientFilterFunction(e, value.uuid, value.name) : () => chooseClientFilter(value.uuid, value.name)}
                                                                                            />

                                                                                        </div>
                                                                                        <div className={"content"}>
                                                                                            <div className={"logoInside"}
                                                                                                // onClick={type !== "assignProduct" ? e => chooseClientFilterFunction(e, value.uuid, value.name) : () => chooseClientFilter(value.uuid, value.name)}
                                                                                            >
                                                                                                {valueBrand.logo ?
                                                                                                    <img src={valueBrand.logo }  />
                                                                                                    :    <FontAwesomeIcon className="newIcon" icon={faMugHot}/>}

                                                                                            </div>
                                                                                            <p>{valueBrand.name}</p>

                                                                                        </div>

                                                                                        <div className={"content-buttons"}>


                                                                                            <button className={`optionsFilterItem`}
                                                                                                    onClick={() => setModalShow(true, "edition", valueBrand.uuid)}
                                                                                            >  <FontAwesomeIcon icon={faPlusCircle}/></button>

                                                                                            <button className={`optionsFilterItem`}
                                                                                                onClick={() => startEditAnything("brand", valueBrand.uuid, valueBrand.name, valueBrand.logo)}
                                                                                            >  <FontAwesomeIcon icon={faPen}/></button>
                                                                                            <button className={`optionsFilterItem`}
                                                                                                onClick={() => showDelete(value.uuid)}
                                                                                            >  <FontAwesomeIcon icon={faTrash}/></button>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={"nana"}>
                                                                                        <ul className={"moveTreeULThirdLevel"}>
                                                                                            {allGroupsEditions !== undefined && allGroupsEditions.length > 0 ? (
                                                                                                <>
                                                                                                    {allGroupsEditions.map((valueEdition, indexBrand) => {
                                                                                                        if(valueEdition.parent === valueBrand.uuid){
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={'filter-brands-'+indexBrand}
                                                                                                                    className={activeThreeLocation.includes(valueEdition.uuid) ? 'activeFilter' : ''}

                                                                                                                >
                                                                                                                    <div className={"papa"}>
                                                                                                                        <div className={"logoInput"}>
                                                                                                                            <input className="jss1595"
                                                                                                                                   type="checkbox"
                                                                                                                                   checked={checkedUUIDZ && checkedUUIDZ.includes(valueEdition.uuid) ? true : false}
                                                                                                                                   onClick={() => selectMoveDestination(valueEdition.uuid)}
                                                                                                                                   onChange={() => selectMoveDestination(valueEdition.uuid)}
                                                                                                                                   data-indeterminate="false"
                                                                                                                                   value=""
                                                                                                                                // onClick={type !== "assignProduct" ? e => chooseClientFilterFunction(e, value.uuid, value.name) : () => chooseClientFilter(value.uuid, value.name)}
                                                                                                                            />

                                                                                                                        </div>
                                                                                                                        <div className={"content"}>
                                                                                                                            <div className={"logoInside"}
                                                                                                                                // onClick={type !== "assignProduct" ? e => chooseClientFilterFunction(e, value.uuid, value.name) : () => chooseClientFilter(value.uuid, value.name)}
                                                                                                                            >
                                                                                                                                {valueEdition.logo ?
                                                                                                                                    <img src={valueEdition.logo }  />
                                                                                                                                    :    <FontAwesomeIcon className="newIcon" icon={faMugHot}/>}

                                                                                                                            </div>
                                                                                                                            <p>{valueEdition.name}</p>
                                                                                                                        </div>
                                                                                                                        <div className={"content-buttons"}>
                                                                                                                            <button className={`optionsFilterItem`}
                                                                                                                               onClick={() => startEditAnything("edition", valueEdition.uuid, valueEdition.name, valueEdition.logo)}
                                                                                                                            >  <FontAwesomeIcon icon={faPen}/></button>
                                                                                                                            <button className={`optionsFilterItem`}
                                                                                                                               onClick={() => showDelete(value.uuid)}
                                                                                                                            >  <FontAwesomeIcon icon={faTrash}/></button>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            );
                                                                                                        }

                                                                                                    })}
                                                                                                </>
                                                                                            ) : null}
                                                                                        </ul>
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    })}
                                                                </>
                                                            ) : null}
                                                        </ul>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </>
                                ) : null}

                            </ul>


                        </div>

                        <div className={"modal-buttons"}>

                            <GlobalButtonFinal  className={"borderBlue btn-circle"} onClick={() => changeMoveShow(false)}>
                                <FormattedMessage {...messages.products_modal_move_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>
                            <GlobalButtonFinal className={"backgroundBlue btn-circle"} data-uuid={productMoveUUID} onClick={() => moveProduct()}>
                                <FontAwesomeIcon   icon={faArrowTurnDownRight}/>   <FormattedMessage {...messages.products_modal_move_button_move}></FormattedMessage>
                            </GlobalButtonFinal>

                        </div>



                    </div>
                </ModalWindow>


            </ModalsWrapper>




        </>
    )
}