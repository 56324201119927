import * as Sentry from '@sentry/nextjs';
import { deleteCookie, getCookie } from 'cookies-next';
import { signOut } from 'next-auth/client';
import { useRouter } from 'next/router';

export const checkAgreement = (token) => {

    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"user/current", {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': "Bearer "+ token, // notice the Bearer before your token
        },
    })
        .then(response => response.json())
        .then(function(data){
            if(data.message !== undefined){
                Sentry.captureException("ser/current"+": "+ data.message + " : " + getCookie("userMail"));
            }

            if(data.agreed_terms_version){
                loadAgreementsDocument(data.agreed_terms_version,token);

            } else {
                window.open("/terms-and-conditions", "_self");

            }

        });

};


const loadAgreementsDocument = (agreedVersion,token) => {


    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"articles/TERMS_AND_CONDITIONS_OF_USE", {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': "Bearer "+ token, // notice the Bearer before your token
        },
    })
        .then(response => response.json())
        .then(function(data){
            if(data.message !== undefined){
                Sentry.captureException("articles/TERMS_AND_CONDITIONS_OF_US"+": "+ data.message + " : " + getCookie("userMail"));
                //   triggerWarning(data.message);
                if(data.status === 401){
                    signOutFunction();
                }

            } else {

                if(!agreedVersion){
                    window.open("/terms-and-conditions", "_self");
                } else {

                    if(data.version !== agreedVersion){
                        window.open("/terms-and-conditions", "_self");
                    }

                }

            }


        });

};
let signOutFunction = () => {
    deleteCookie('userTeam');
    deleteCookie('userTeamUUID');
    deleteCookie('usrA');
    const router = useRouter();
    signOut({ redirect: false }).then(() => {
        router.push('/'); // Redirect to the dashboard page after signing out
    });

    // signOut();
};
